import React, {useState, useEffect}from 'react'
import { removeFromFavorites, addToFavorites, guardarLogProducto} from '../../firebase/config'
import { Link } from 'react-router-dom'
import { upperFL } from '../../utils/utils'
import "./ProductosAlerts.css"

export const ProductosAlerts = ( {producto, state, user} ) => {

  /*
    Stages:
    0: initiated
    1: loading
    2: login completed
    3: login but no username
    4: not logged
    5: ya existe el username
    6: nuevo username: click para continuar
    */

    const currentUser = user;
    const [userLikes, setUserLikes] = useState([]);

  // Efecto para actualizar los likes locales cuando cambia el usuario
  useEffect(() => {
    if (currentUser && currentUser.likes) {
      setUserLikes(currentUser.likes);
    }
  }, [currentUser]);

  const handleLikeClick = async (producto) => {
    if (currentUser) {
      try {
        if (userLikes.includes(producto.id)) {
          // Si ya está en favoritos, elimínalo
          await removeFromFavorites(currentUser.uid, producto.id);
        } else {
          // Si no está en favoritos, añádelo
          await addToFavorites(currentUser.uid, producto.id);
        }

        // Actualiza el estado local con los likes actualizados
        setUserLikes((prevLikes) =>
          prevLikes.includes(producto.id)
            ? prevLikes.filter((like) => like !== producto.id)
            : [...prevLikes, producto.id]
        );
      } catch (error) {
        console.error('Error al manejar el like:', error);
      }
    }
  }

  const handleProductClick = async (producto, currentUser, premium) => {
    if (!currentUser) {
      console.warn('Usuario no logueado. No se registrará el evento.');
      return;
    }
  
    const logData = {
      user: currentUser.uid, // ID del usuario
      username:currentUser.username,
      tipo_prenda: producto.clothe, // Nombre del producto
      size: producto.size,
      brand: producto.brand,
      estate: producto.condition,
      city_producto: producto.city,
      platform: producto.web,
      price: producto.price,
      category: producto.category, // Si tienes categorías
      color: producto.color,
      sex_product: producto.sex, // Si el producto tiene género
      sex_user: currentUser.gender, // Suponiendo que el perfil tiene género
      birthdate_user: currentUser.birthdate, // Suponiendo que el perfil tiene edad
      province_user: currentUser.province, // Localización del usuario
      is_premium: state === 7, // Si el usuario es premium
      is_alert:true,
    };
  
    try {
      await guardarLogProducto(logData); // Envía los datos al backend
    } catch (error) {
      console.error(error);
    }
  };

  if(state===2){
    return (
      <div className="producto-card">
          <img src={producto.images} alt={upperFL(producto.title)}/>
          <div className="producto-info">
              <h3>{upperFL(producto.title)}</h3>
              <ul className="caracteristicas">
                  <li><strong>Talla:</strong> {producto.size.toUpperCase()}</li>
                  <li><strong>Estado:</strong> {upperFL(producto.condition)}</li>
                  <li><strong>Marca:</strong> {upperFL(producto.brand)}</li>
                  <li><strong>Ciudad:</strong> {upperFL(producto.city)}</li>
                  <li><strong>Web:</strong> {upperFL(producto.web)}</li>
                  <li><strong>Precio:</strong> {producto.price}€</li>
              </ul>
              <div className='botones-producto'>
                <a href={producto.link} target="_blank" rel="noopener noreferrer">
                  <button>
                    Ver Producto
                  </button>
                </a>
                <Link to="/premium">
                  <button className='boton-favoritos'>
                    {'Hazte Premium para agregar favoritos'}
                  </button>
                </Link>
              </div>
              
          </div>
      </div>
    )
  }

  if(state === 4){
    return (
      <div className="producto-card">
          <img src={producto.images} alt={upperFL(producto.title)}/>
          <div className="producto-info">
              <h3>{upperFL(producto.title)}</h3>
              <ul className="caracteristicas">
                  <li><strong>Talla:</strong> {producto.size.toUpperCase()}</li>
                  <li><strong>Estado:</strong> {upperFL(producto.condition)}</li>
                  <li><strong>Marca:</strong> {upperFL(producto.brand)}</li>
                  <li><strong>Ciudad:</strong> {upperFL(producto.city)}</li>
                  <li><strong>Web:</strong> <Link to="/login">Login para ver</Link></li>
                  <li><strong>Precio:</strong> {producto.price}€</li>
              </ul>
              <div className='botones-producto'>
                <Link to="/login"><button>Login para ver el producto</button></Link>
              </div>
          </div>
      </div>
    )
  }
  if(state===7){
    return (
      <div className="producto-card">
          <img src={producto.images} alt={upperFL(producto.title)}/>
          <div className="producto-info">
              <h3>{upperFL(producto.title)}</h3>
              <ul className="caracteristicas">
              <li><strong>Talla:</strong> {producto.size.toUpperCase()}</li>
                  <li><strong>Estado:</strong> {upperFL(producto.condition)}</li>
                  <li><strong>Marca:</strong> {upperFL(producto.brand)}</li>
                  <li><strong>Ciudad:</strong> {upperFL(producto.city)}</li>
                  <li><strong>Web:</strong> {upperFL(producto.web)}</li>
                  <li><strong>Precio:</strong> {producto.price}€</li>
              </ul>
              <div className='botones-producto'>
                <a
                      href={producto.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => handleProductClick(producto, currentUser, state)}
                    >
                      <button>Ver Producto</button>
                </a>
                <button 
                   className={`boton-favoritos ${userLikes.includes(producto.id) ? 'quitar' : 'agregar'}`}
                   onClick={() => handleLikeClick(producto)}>
                  {userLikes.includes(producto.id) ? 'Quitar de favoritos' : 'Agregar a favoritos'}
                </button>
              </div>
          </div>
      </div>
    )
  }
}

export default ProductosAlerts