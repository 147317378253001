import algoliasearch from 'algoliasearch';
import { history } from 'instantsearch.js/es/lib/routers';


const APPLICATION_ID = process.env.REACT_APP_ALGOLIA_APPLICATION_ID;
const SEARCH_API_KEY = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;

export const searchClient = algoliasearch(APPLICATION_ID, SEARCH_API_KEY);
  
export const routing = {
    router: history(),
  
    stateMapping: {
      stateToRoute(uiState) {
        const indexUiState = uiState['productos_erastro'];
        return {
          query: indexUiState.query,
          page: indexUiState.page,
          clothe: indexUiState.refinementList?.clothe,
          sex: indexUiState.refinementList?.sex,
          web: indexUiState.refinementList?.web,
          size: indexUiState.refinementList?.size,
          condition: indexUiState.refinementList?.condition,
          color: indexUiState.refinementList?.color,
          city: indexUiState.refinementList?.city,
        };
      },
  
      routeToState(routeState) {
        return {
            productos_erastro: {
                query: routeState.query,
                refinementList: {
                    clothe: routeState.clothe,
                    sex: routeState.sex,
                    web: routeState.web,
                    size: routeState.size,
                    condition: routeState.condition,
                    color: routeState.color,
                    city: routeState.city    
                },
                page: routeState.page
          },
        };
      },
    },
  };
  
  