import React, { useState } from 'react'
import {auth} from "../../firebase/config"
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { AuthProvider } from '../AuthProvider/AuthProvider'
import './Login.css';


export const Login = () => {

    /*
    Stages:
    0: initiated
    1: loading
    2: login completed
    3: login but no username
    4: not logged
    5: ya existe el username
    */
    const navigate = useNavigate()
    const [state, setCurrentState] = useState(0);

    async function handleOnClick(){
        const googleProvider = new GoogleAuthProvider()
        await signInWithGoogle( googleProvider)

        async function signInWithGoogle(googleProvider){
            try {
                const res = await signInWithPopup(auth, googleProvider);
                //console.log(res)
            } catch (error) {
                console.error(error)
            }
        }
    }
    function handleUserPremium(user){
        setCurrentState(7)
        navigate('/')
        
    }

    function handleUserLoggIn(user){
        navigate('/')
    }

    function handleUserNotRegistered(){
        navigate('/elige-usuario')
    }

    function handleUserNotLoggIn(user){
        setCurrentState(4)
    }

    if(state === 1){
        return <div>Loading...</div>
    }

    if (state === 4) {
        return (
        <div className='login'>
            <div className='login-texto'>
                <h1>¡Regístrate y accede a miles de prendas únicas!</h1>
                <p>
                    Descubre el verdadero potencial de nuestra plataforma. 
                    Inicia sesión y desbloquea funciones exclusivas que harán tu experiencia mucho más completa:
                </p>
                <ul>
                    <li>Descubre <strong>donde comprar</strong> los productos y accede a ellos para explorar cada detalle.</li>
                    <li>Prueba nuestro <strong>sistema de alertas</strong> para encontrar el producto que buscas (Límite 1 alerta).</li>
                </ul>
                <h2>¿Quieres más? ¡Inicia sesión y hazte Premium!</h2>
                    <p>
                        Desbloquea las siguientes funcionalidades que harán que ahorres tiempo y dinero:
                    </p>
                    <ul>
                        <li>Guarda tus <strong>productos favoritos</strong> hasta que decidas comprarlos.</li>
                        <li>Disfruta de nuestro <strong>sistema de alertas</strong> de productos pudiendo crear hasta 100 alertas.</li>
                        <li>Navega por la web <strong>sin anuncios</strong>.</li>
                    </ul>
                <p>
                    <em>¿A qué esperas? Iniciar sesión es gratis, regístrate y llevar tu experiencia al siguiente nivel.</em>
                </p>
                <button className='boton-login' onClick={handleOnClick}>
                    🚀 Iniciar Sesión con Google
                </button>
                <div className='premium-info'>
                    
                </div>
            </div>

            <img className='img-login' src="/imgs/login.jpeg" alt="chica mirando ropa en un baul" />
        </div>
        
        );
    }

    if (state === 5) {
        return (
        <div className='login'>
            <h1>Iniciar sesión con google</h1>
            <button className='boton-login' onClick={handleOnClick}>Login with Google</button>
        </div>
        );
    }

  return (
    <AuthProvider
      onUserLoggIn={handleUserLoggIn}
      onUserNotLoggIn={handleUserNotLoggIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserPremium={handleUserPremium}
      >
        <div>Loading...componente</div>
    </AuthProvider>
  );
}
