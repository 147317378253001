import React, { useEffect } from 'react'
import {auth, getUserInfo, registerNewUser, userExists} from "../../firebase/config"
import { onAuthStateChanged } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { fecha } from '../../utils/utils'
import { getSubscriptionStatus} from '../../firebase/config'

export const AuthProvider = ({children, onUserLoggIn, onUserNotLoggIn, onUserNotRegistered, onUserPremium}) => {

    const navigate = useNavigate()

    useEffect(() => {
        onAuthStateChanged(auth, async(user) => {
            if(user){
                const isRegistered = await userExists(user.uid);
                if(isRegistered){
                    const userInfo = await getUserInfo(user.uid);
                    if(userInfo.processCompleted){
                        const subs = await getSubscriptionStatus(user.uid)
                        if(subs){
                            onUserPremium(userInfo);
                        }else{
                            onUserLoggIn(userInfo);
                        }
                    }else{
                        onUserNotRegistered(userInfo)
                    }

                }else{
                    await registerNewUser({
                        uid: user.uid,
                        email: user.email,
                        displayName: user.displayName,
                        registerDate: fecha(),
                        username: '',
                        productLikes: [],
                        processCompleted: false
                    })
                    onUserNotRegistered(user);
                }
            }else{
                onUserNotLoggIn()
            }
        })
    },[navigate, onUserLoggIn, onUserNotLoggIn, onUserNotRegistered, onUserPremium])

  return (
    <div>{children}</div>
  )
}