import React, { useState } from 'react'
import "./Nosotros.css"
import { Contacto } from '../Contacto/Contacto'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' 
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

export const Nosotros = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Función para manejar la apertura y cierre de cada pregunta
  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="div-nosotros">
      <img className='img-nosotros' src="/imgs/ropa.jpg" alt="ropa en perchas" />
      <div className="div-texto">
        <h1>Sobre Nosotros</h1>

        <div className="about-section">
          <h2>Redefiniendo la Experiencia de Comprar de Moda Segunda Mano</h2>

          <p>En eRastro revolucionamos la forma de disfrutar la moda de segunda mano, reuniendo prendas únicas de múltiples tiendas online en un solo lugar. Inspirados por el encanto del Rastro de Madrid, hemos creado una plataforma que combina sostenibilidad, estilo y conveniencia. Aquí no solo encuentras ropa, sino que tienes la oportunidad de ahorrar mientras cuidas el planeta. Descubre una forma de buscar ropa desde la comodidad de tu hogar.</p> 
          <p>¡Bienvenido a eRastro, donde tus compras combinan estilo, ahorro y sostenibilidad para marcar la diferencia!</p>
          </div>

        {/* Sección de Q&A */}
        <div className="faq-section">
          <h2>Preguntas Frecuentes (Q&A)</h2>

          {/* Pregunta 1 */}
          <div className="faq-item">
            <button className="faq-question" onClick={() => handleToggle(0)}>
              ¿Qué es eRastro?
              <span className={`faq-arrow ${activeIndex === 0 ? 'open' : ''}`}><FontAwesomeIcon icon={faChevronDown} /></span>
            </button>
            {activeIndex === 0 && (
              <div className="faq-answer">
                <p>eRastro es una plataforma que centraliza productos de diversas tiendas de segunda mano online. Ofrecemos herramientas como filtros avanzados y un buscador por texto para facilitar tu búsqueda. Actualmente se muestran productos de Vinted, Wallapop y Ebay. También puedes guardar tus productos favoritos y activar alertas para que te avisemos cuando aparezca lo que buscas.</p>
              </div>
            )}
          </div>

          {/* Pregunta 2 */}
          <div className="faq-item">
            <button className="faq-question" onClick={() => handleToggle(1)}>
              ¿En eRastro se pueden comprar productos?
              <span className={`faq-arrow ${activeIndex === 1 ? 'open' : ''}`}><FontAwesomeIcon icon={faChevronDown} /></span>
            </button>
            {activeIndex === 1 && (
              <div className="faq-answer">
                <p>No, eRastro no vende productos directamente. Recopilamos productos de tiendas de segunda mano para ayudarte a encontrar lo que necesitas de forma rápida y sencilla. Las compras se realizan directamente en las tiendas de segunda mano.</p>
              </div>
            )}
          </div>

          {/* Pregunta 3 */}
          <div className="faq-item">
            <button className="faq-question" onClick={() => handleToggle(2)}>
              ¿Cómo funcionan las alertas?
              <span className={`faq-arrow ${activeIndex === 2 ? 'open' : ''}`}><FontAwesomeIcon icon={faChevronDown} /></span>
            </button>
            {activeIndex === 2 && (
              <div className="faq-answer">
                <p>Las alertas te notifican cuando una prenda que cumple con tus criterios está disponible. Configura tus alertas indicando detalles como talla, tipo de prenda o estado. Cuando entre un producto nuevo en nuestro sistema y haya coincidencias, recibirás un correo en tu email registrado.</p>
                <p>Si no recibes notificaciones, te recomendamos usar criterios más generales o revisar tu carpeta de spam. También puedes consultar en el apartado de alertas los productos que cumplieron con tus requisitos en la última semana.</p>
              </div>
            )}
          </div>

          {/* Pregunta 4 */}
          <div className="faq-item">
            <button className="faq-question" onClick={() => handleToggle(3)}>
              ¿Cómo puedo gestionar mi suscripción?
              <span className={`faq-arrow ${activeIndex === 3 ? 'open' : ''}`}><FontAwesomeIcon icon={faChevronDown} /></span>
            </button>
            {activeIndex === 3 && (
              <div className="faq-answer">
                <p>Puedes suscribirte a un plan mensual o anual y cambiar entre ellos cuando desees. Si decides cancelar, tu suscripción permanecerá activa hasta el final del periodo ya pagado, pero no se renovará.</p>
              </div>
            )}
          </div>

          {/* Pregunta 5 */}
          <div className="faq-item">
            <button className="faq-question" onClick={() => handleToggle(4)}>
              ¿Qué pasa si borro mi cuenta?
              <span className={`faq-arrow ${activeIndex === 4 ? 'open' : ''}`}><FontAwesomeIcon icon={faChevronDown} /></span>
            </button>
            {activeIndex === 4 && (
              <div className="faq-answer">
                <p>Al borrar tu cuenta, se eliminará tu usuario y se cancelarán automáticamente todas las suscripciones activas. Ten en cuenta que esta acción es irreversible.</p>
              </div>
            )}
          </div>
        </div>


        {/* Sección de contacto */}
        <div>
          <Contacto />
        </div>
        <div className='paginas-legales'>
          <Link to="/aviso-legal">Aviso legal</Link>
          <Link to="/politica-cookies">Política de Cookies</Link>
          <Link to="/politica-privacidad">Política de privacidad</Link>
        </div>
      </div>
    </div>
  )
}
