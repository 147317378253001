import React, { useEffect, useState } from 'react';
import { AuthProvider } from '../AuthProvider/AuthProvider';
import './Premium.css';
import { getStripePlans, pay } from '../../firebase/config';
import { useNavigate, Link } from 'react-router-dom';

export const Premium = () => {
  const [planes, setPlanes] = useState([]);
  const navigate = useNavigate();
  const [state, setState] = useState(0);
  const [currentUser, setCurrentUser] = useState({});
  const [loading, setLoading] = useState(false); // Estado para controlar la visualización del indicador de carga

  useEffect(() => {
    async function fetchPlanes() {
      try {
        const planesData = await getStripePlans();
        console.log('Planes obtenidos:', planesData);
        setPlanes(planesData);
      } catch (error) {
        console.error('Error al obtener los planes:', error);
      }
    }
  
    fetchPlanes();
  }, []);

  async function handleSubscription(priceId) {
    setLoading(true); // Activar el indicador de carga antes de realizar el pago
    try {
      await pay(currentUser, priceId);
      // El pago se ha completado y la redirección se ha realizado dentro de la función `pay`
    } catch (error) {
      console.error('Error durante el proceso de pago:', error);
      alert('Ocurrió un error durante el proceso de pago. Por favor, inténtalo de nuevo.');
    } finally {
      setLoading(false); // Desactivar el indicador de carga después de realizar el pago (exitoso o fallido)
    }
  }

  function handleUserPremium(user) {
    setCurrentUser(user);
    setState(7);
  }

  function handleUserLoggIn(user) {
    setCurrentUser(user);
    setState(2);
  }

  function handleUserNotRegistered(user) {
    navigate('login');
    setState(3);
  }

  function handleUserNotLoggIn() {
    navigate('/login');
  }

  if (state === 2) {
    return (
      <div className="subscription-plans">
        {loading && (
          <div className="loading-overlay">
            <div className="loader"></div>
          </div>
        )}

        <h1>Planes de Suscripción</h1>
        <p>Elige un plan para poder acceder a los servicios premium, suscribete con el plan anual para ahorar un 30%</p>
        <div className='todos-planes'>
          {Object.entries(planes).map(([planid, planData]) => {
            return (
              <div className="plan" key={planid}>
                <h2 className="plan-name">{planData.name}</h2>
                <p className="plan-description">{planData.description}</p>
                <p className="plan-price">{planData.prices && planData.prices.priceData.unit_amount/100 + '€'}</p>
                <button className="subscribe-btn" onClick={() => handleSubscription(planData.prices.priceId)}>
                  Suscribirse
                </button>
              </div>
            );
          })}
        </div>
        <div className='parrafo-planes'>
          <h3>Descubre todos los beneficios de suscribirte a nuestro servicio premium:</h3>
          <ul>
            <li>
              <p>Crea alertas hasta 100 personalizadas y encuentra las mejores ofertas antes que nadie. Ahorra tiempo y dinero a la hora de encontrar ese artículo que deseas.</p>
            </li>
            <li>
              <p>Guarda tus productos favoritos y ten acceso a ellos en cualquier momento. Encuentra las prendas que buscas sin complicaciones y toma decisiones de compra más inteligentes.</p>
            </li>
            <li>
              <p>Disfruta de la web sin anuncios ni interrupciones</p>
            </li>
          </ul>
          <p>Suscribirte a eRastro no solo te permite ahorrar dinero y tiempo, sino que también marca la diferencia en el planeta. Únete hoy y descubre cómo la moda vintage puede ser asequible, consciente y emocionante. ¡Suscríbete ahora y comienza a disfrutar de todos estos beneficios!</p>
        </div>

      </div>
    );
  }

  return (
    <AuthProvider
      onUserLoggIn={handleUserLoggIn}
      onUserNotLoggIn={handleUserNotLoggIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserPremium={handleUserPremium}
    ></AuthProvider>
  );
};
