import React, { useState, useEffect } from 'react';
import { addAlert, getAlertsByUserId, handleDeleteAlert, getAlertProducts, getSubscriptionStatus } from '../../firebase/config'
import { useNavigate, Link } from 'react-router-dom'
import { AuthProvider } from '../AuthProvider/AuthProvider'
import "./Alertas.css"
import { upperFL } from '../../utils/utils'
import ProductosAlerts from '../ProductosAlerts/ProductosAlerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShirt } from '@fortawesome/free-solid-svg-icons'

export const Alertas = () => {

  const navigate = useNavigate()
  const [currentUser, setCurrentUser] = useState({})
  const [state, setState] = useState(0);
  const [premium, setPremium] = useState([]);
  
  const [alerta, setAlerta] = useState({
      tituloAlerta: '',
      prenda: '',
      sexo: '',
      talla: '',
      estado: '',
      marca: '',
      color: '',
      ubicacion: '',
      precioMax: ''
    });
  
    useEffect(() => {
      // Lógica para obtener las alertas del usuario actual al cargar el componente
      const fetchSubs = async () => {
        try {
          if (currentUser && currentUser.uid) {
            const isPremium = await getSubscriptionStatus(currentUser.uid);
            setPremium(isPremium);
          }
        } catch (error) {
          console.error('Error al obtener las suscripciones:', error);
        }
      };
    
      fetchSubs();
    }, [currentUser]);

    useEffect(() => {
      // Este efecto se ejecutará cada vez que currentUser cambie
      if (premium === true) {
        setState(7);
      }
    }, [currentUser, premium]);
  
    function handleUserPremium(user){
      setCurrentUser(user)
      setState(7)
    }
  
    function handleUserLoggIn(user){
      setCurrentUser(user)
      setState(2)
    }
  
    function handleUserNotRegistered(user){
        navigate('/login')
    }
  
    function handleUserNotLoggIn(){
        navigate('/login')
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAlerta({ ...alerta, [name]: value });
    };

    const [alertasGuardadas, setAlertasGuardadas] = useState([]);

    
    useEffect(() => {
      const fetchAlerts = async () => {
        try {
          if (currentUser && currentUser.uid) {
            const alerts = await getAlertsByUserId(currentUser.uid);
            setAlertasGuardadas(alerts);
            localStorage.setItem('alertas', JSON.stringify(alerts)); // Guardar en localStorage
          }
        } catch (error) {
          console.error('Error al obtener las alertas:', error);
        }
      };
      fetchAlerts();
    }, [currentUser]);

    const handleDeleteAndRefresh = async (userId, alertId) => {
      try {
        await handleDeleteAlert(userId, alertId);
        const updatedAlerts = alertasGuardadas.filter(alert => alert.id !== alertId);
        setAlertasGuardadas(updatedAlerts);
        localStorage.setItem('alertas', JSON.stringify(updatedAlerts)); // Actualizar localStorage
      } catch (error) {
        console.error('Error al eliminar la alerta:', error);
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const alertaConFecha = { ...alerta, creationDate: Date.now() };

      try {
        const alertId = await addAlert(currentUser.uid, alertaConFecha, premium );
        const newAlert = { id: alertId, data: alertaConFecha };
        setAlertasGuardadas([...alertasGuardadas, newAlert]); // Agrega la nueva alerta al estado
    
        // Cargar los productos de la nueva alerta
        const productos = await getAlertProducts(alertaConFecha);
        setProducts({
          ...products,
          [alertId]: productos
        }); // Agrega los productos al estado de productos usando el ID de la alerta como clave
      } catch (error) {
        console.error('Error al crear la alerta:', error);
        // Manejar el error según sea necesario
      }
    };
    

    const [showProducts, setShowProducts] = useState(false);

    const [products, setProducts] = useState({});

    const loadProductsAlert = async (alertData, alertId) => {
      try {
        const productos = await getAlertProducts(alertData);
        setProducts(prevState => ({
          ...prevState,
          [alertId]: productos
        }));
      } catch (error) {
        console.error('Error al cargar los productos:', error);
        // Manejar el error según sea necesario
      }
    };

    const toggleProducts = async (alertId) => {
  setShowProducts(prevState => ({
    ...prevState,
    [alertId]: !prevState[alertId]
  }));

  if (!products[alertId]) {
    const alertIndex = alertasGuardadas.findIndex(alert => alert.id === alertId);
    await loadProductsAlert(alertasGuardadas[alertIndex].data, alertId);
  }
};

if(state === 2){
  return (
    <div className='alertas'>
      <div className='crear-alertas'>
        <h2>Crear Alerta de Producto</h2>
        <p>Los campos con * son obligatorios</p>
        <form onSubmit={handleSubmit} className='form-container'>
          <div>
            <label htmlFor="tituloAlerta">*Título:</label>
            <input
              type="text"
              id="tituloAlerta"
              name="tituloAlerta"
              value={alerta.tituloAlerta}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="prenda">*Prenda:</label>
            <select
              id="prenda"
              name="prenda"
              value={alerta.prenda}
              onChange={handleChange}
              required
            >
              <option value="">Seleccionar</option>
              <option value="abrigos">Abrigos</option>
              <option value="americanas">Americanas</option>
              <option value="batas">Batas</option>
              <option value="banaedores">Bañadores</option>
              <option value="bermudas">Bermudas</option>
              <option value="bikinies">Bikinies</option>
              <option value="blazers">Blazers</option>
              <option value="blusas">Blusas</option>
              <option value="botas">Botas</option>
              <option value="bufandas">Bufandas</option>
              <option value="calcetines">Calcetines</option>
              <option value="camisas">Camisas</option>
              <option value="camisetas">Camisetas</option>
              <option value="cardigans">Cardigans</option>
              <option value="chalecos">Chalecos</option>
              <option value="chandales">Chándales</option>
              <option value="chaquetas">Chaquetas</option>
              <option value="chupas">Chupas</option>
              <option value="cinturones">Cinturones</option>
              <option value="corbatas">Corbatas</option>
              <option value="faldas">Faldas</option>
              <option value="fulares">Fulares</option>
              <option value="gafas">Gafas</option>
              <option value="gorros">Gorros</option>
              <option value="guantes">Guantes</option>
              <option value="jerseys">Jerseys</option>
              <option value="leggings">Leggings</option>
              <option value="mallas">Mallas</option>
              <option value="minifaldas">Minifaldas</option>
              <option value="monos">Monos</option>
              <option value="pantalones">Pantalones</option>
              <option value="pijamas">Pijamas</option>
              <option value="polos">Polos</option>
              <option value="relojes">Relojes</option>
              <option value="sombreros">Sombreros</option>
              <option value="sudaderas">Sudaderas</option>
              <option value="tops">Tops</option>
              <option value="trajes">Trajes</option>
              <option value="vestidos">Vestidos</option>
              <option value="zapatillas">Zapatillas</option>
              <option value="zapatos">Zapatos</option>
            </select>
          </div>
          <div>
            <label htmlFor="sexo">*Sexo:</label>
            <select
              id="sexo"
              name="sexo"
              value={alerta.sexo}
              onChange={handleChange}
              required
            >
              <option value="">Seleccionar</option>
              <option value="hombre">Hombre</option>
              <option value="mujer">Mujer</option>
            </select>
          </div>
          <div>
            <label htmlFor="talla">*Talla:</label>
            <select
              id="talla"
              name="talla"
              value={alerta.talla}
              onChange={handleChange}
              required
            >
              <option value="">Seleccionar</option>
              <option value="xs">XS</option>
              <option value="s">S</option>
              <option value="m">M</option>
              <option value="l">L</option>
              <option value="xl">XL</option>
              <option value="xxl">XXL</option>
              <option value="3xl">3XL</option>
              <option value="4xl">4XL</option>
            </select>
          </div>
          <div>
            <label htmlFor="estado">*Estado:</label>
            <select
              id="estado"
              name="estado"
              value={alerta.estado}
              onChange={handleChange}
              required           
            >
              <option value="">Seleccionar</option>
              <option value="nuevo">Nuevo</option>
              <option value="como nuevo">Cómo nuevo</option>
              <option value="muy bueno">Muy bueno</option>
              <option value="bueno">Bueno</option>
              <option value="usado">Usado</option>
            </select>
          </div>
          <div>
            <label htmlFor="marca">Marca:</label>
            <select
              id="marca"
              name="marca"
              value={alerta.marca}
              onChange={handleChange}           
            >
              <option value="">Seleccionar</option>
              <option value="adidas">Adidas</option>
              <option value="abercrombie & fitch">Abercrombie & Fitch</option>
              <option value="air force">Air Force</option>
              <option value="alcott">Alcott</option>
              <option value="armand thiery">Armand Thiery</option>
              <option value="asics">Asics</option>
              <option value="asos">ASOS</option>
              <option value="balenciaga">Balenciaga</option>
              <option value="bershka">Bershka</option>
              <option value="bizzbee">Bizzbee</option>
              <option value="bonobo">Bonobo</option>
              <option value="boohoo">Boohoo</option>
              <option value="boohooman">BoohooMAN</option>
              <option value="brice">Brice</option>
              <option value="c&a">C&A</option>
              <option value="cuir">CUIR</option>
              <option value="calvin klein">Calvin Klein</option>
              <option value="cargo">Cargo</option>
              <option value="carhartt">Carhartt</option>
              <option value="carrera">Carrera</option>
              <option value="celio">Celio</option>
              <option value="champion">Champion</option>
              <option value="chevignon">Chevignon</option>
              <option value="columbia">Columbia</option>
              <option value="converse">Converse</option>
              <option value="cortefiel">Cortefiel</option>
              <option value="corteiz">Corteiz</option>
              <option value="decathlon">Decathlon</option>
              <option value="desigual">Desigual</option>
              <option value="devred">Devred</option>
              <option value="diadora">Diadora</option>
              <option value="dickies">Dickies</option>
              <option value="diesel">Diesel</option>
              <option value="dockers">Dockers</option>
              <option value="dsquared2">Dsquared2</option>
              <option value="easy wear">Easy Wear</option>
              <option value="eden park">Eden Park</option>
              <option value="el corte ingles">El Corte Inglés</option>
              <option value="element">Element</option>
              <option value="ellesse">Ellesse</option>
              <option value="esprit">Esprit</option>
              <option value="fila">FILA</option>
              <option value="fred perry">Fred Perry</option>
              <option value="g-star">G-Star</option>
              <option value="guess">GUESS</option>
              <option value="geox">Geox</option>
              <option value="giacca">Giacca</option>
              <option value="giuseppe zanotti">Giuseppe Zanotti</option>
              <option value="gemo">Gémo</option>
              <option value="h&m">H&M</option>
              <option value="hogan">Hogan</option>
              <option value="hollister">Hollister</option>
              <option value="hugo boss">Hugo Boss</option>
              <option value="inside(24)">Inside(24)</option>
              <option value="jack & jones">Jack & Jones</option>
              <option value="jaded london">Jaded London</option>
              <option value="jeckerson">Jeckerson</option>
              <option value="jennyfer">Jennyfer</option>
              <option value="joma">Joma</option>
              <option value="jordan">Jordan</option>
              <option value="jules">Jules</option>
              <option value="kalenji">Kalenji</option>
              <option value="kaporal">Kaporal</option>
              <option value="kappa">Kappa</option>
              <option value="kenzo">Kenzo</option>
              <option value="kiabi">Kiabi</option>
              <option value="kipsta">Kipsta</option>
              <option value="lacoste">Lacoste</option>
              <option value="le coq sportif">Le Coq Sportif</option>
              <option value="lee">Lee</option>
              <option value="lefties">Lefties</option>
              <option value="levi strauss & co.">Levi Strauss & Co.</option>
              <option value="levis">Levi's</option>
              <option value="lidl">Lidl</option>
              <option value="local">Local</option>
              <option value="lotto">Lotto</option>
              <option value="mizuno">MIZUNO</option>
              <option value="mango">Mango</option>
              <option value="massimo dutti">Massimo Dutti</option>
              <option value="minus twø">Minus Twø</option>
              <option value="munich">Munich</option>
              <option value="nasa">NASA</option>
              <option value="napapijri">Napapijri</option>
              <option value="new balance">New Balance</option>
              <option value="nike">Nike</option>
              <option value="ovs">OVS</option>
              <option value="off-white">Off-White</option>
              <option value="pepe jeans">Pepe Jeans</option>
              <option value="polo">Polo</option>
              <option value="primark">Primark</option>
              <option value="pull & bear">Pull & Bear</option>
              <option value="puma">Puma</option>
              <option value="pyrex">Pyrex</option>
              <option value="quiksilver">Quiksilver</option>
              <option value="ralph lauren">Ralph Lauren</option>
              <option value="redskins">Redskins</option>
              <option value="reebok">Reebok</option>
              <option value="salomon">Salomon</option>
              <option value="saucony">Saucony</option>
              <option value="scalpers">Scalpers</option>
              <option value="scarpe">Scarpe</option>
              <option value="schott">Schott</option>
              <option value="sfera">Sfera</option>
              <option value="shein">Shein</option>
              <option value="shimano">Shimano</option>
              <option value="springfield">Springfield</option>
              <option value="stradivarius">Stradivarius</option>
              <option value="superdry">Superdry</option>
              <option value="tex">TEX</option>
              <option value="teddy smith">Teddy Smith</option>
              <option value="the north face">The North Face</option>
              <option value="timberland">Timberland</option>
              <option value="tommy hilfiger">Tommy Hilfiger</option>
              <option value="tommy jeans">Tommy Jeans</option>
              <option value="umbro">Umbro</option>
              <option value="under armour">Under Armour</option>
              <option value="uniqlo">Uniqlo</option>
              <option value="uomo">Uomo</option>
              <option value="vans">Vans</option>
              <option value="vera pelle">Vera Pelle</option>
              <option value="vintage dressing">Vintage Dressing</option>
              <option value="volcom">Volcom</option>
              <option value="woolrich">Woolrich</option>
              <option value="yeezy">Yeezy</option>
              <option value="zara">Zara</option>


            </select>
          </div>
          <div>
            <label htmlFor="color">Color:</label>
            <select
              id="color"
              name="color"
              value={alerta.color}
              onChange={handleChange}           
            >
              <option value="">Seleccionar</option>
              <option value="amarillo">Amarillo</option>
              <option value="azul">Azul</option>
              <option value="beige">Beige</option>
              <option value="blanco">Blanco</option>
              <option value="celeste">Celeste</option>
              <option value="dorado">Dorado</option>
              <option value="gris">Gris</option>
              <option value="marron">Marrón</option>
              <option value="morado">Morado</option>
              <option value="naranja">Naranja</option>
              <option value="negro">Negro</option>
              <option value="plateado">Plateado</option>
              <option value="rojo">Rojo</option>
              <option value="rosa">Rosa</option>
              <option value="turquesa">Turquesa</option>
              <option value="verde">Verde</option>
              <option value="violeta">Violeta</option>
            </select>
          </div>
          <div>
            <label htmlFor="ubicacion">Ubicación:</label>
            <select
              id="ubicacion"
              name="ubicacion"
              value={alerta.ubicacion}
              onChange={handleChange}
            >
              <option value="">Seleccionar</option>
              <option value="alava">Álava</option>
              <option value="albacete">Albacete</option>
              <option value="alicante">Alicante</option>
              <option value="almeria">Almería</option>
              <option value="asturias">Asturias</option>
              <option value="avila">Ávila</option>
              <option value="badajoz">Badajoz</option>
              <option value="baleares">Baleares (Illes)</option>
              <option value="barcelona">Barcelona</option>
              <option value="burgos">Burgos</option>
              <option value="caceres">Cáceres</option>
              <option value="cadiz">Cádiz</option>
              <option value="cantabria">Cantabria</option>
              <option value="castellon">Castellón</option>
              <option value="ceuta">Ceuta</option>
              <option value="ciudadreal">Ciudad Real</option>
              <option value="cordoba">Córdoba</option>
              <option value="cuenca">Cuenca</option>
              <option value="girona">Gerona (Girona)</option>
              <option value="granada">Granada</option>
              <option value="guadalajara">Guadalajara</option>
              <option value="guipuzcoa">Guipúzcoa (Gipuzkoa)</option>
              <option value="huelva">Huelva</option>
              <option value="huesca">Huesca</option>
              <option value="jaen">Jaén</option>
              <option value="laCoruna">La Coruña (A Coruña)</option>
              <option value="larioja">La Rioja</option>
              <option value="lasPalmas">Las Palmas</option>
              <option value="leon">León</option>
              <option value="lleida">Lérida (Lleida)</option>
              <option value="lugo">Lugo</option>
              <option value="madrid">Madrid</option>
              <option value="malaga">Málaga</option>
              <option value="melilla">Melilla</option>
              <option value="murcia">Murcia</option>
              <option value="navarra">Navarra</option>
              <option value="ourense">Orense (Ourense)</option>
              <option value="palencia">Palencia</option>
              <option value="pontevedra">Pontevedra</option>
              <option value="salamanca">Salamanca</option>
              <option value="segovia">Segovia</option>
              <option value="sevilla">Sevilla</option>
              <option value="soria">Soria</option>
              <option value="tarragona">Tarragona</option>
              <option value="tenerife">Tenerife</option>
              <option value="teruel">Teruel</option>
              <option value="toledo">Toledo</option>
              <option value="valencia">Valencia</option>
              <option value="valladolid">Valladolid</option>
              <option value="vizcaya">Vizcaya (Bizkaia)</option>
              <option value="zamora">Zamora</option>
              <option value="zaragoza">Zaragoza</option>
            </select>
          </div>
          <div>
            <label htmlFor="precioMax">Precio Máximo:</label>
            <input
              type="number"
              id="precioMax"
              name="precioMax"
              value={alerta.precioMax}
              onChange={handleChange}          
            />
          </div>
          <button type="submit" className='botton-crear-alerta'>Crear Alerta</button>
        </form>
      </div>
      <div className='mostrar-alertas'>
        <h2>Alertas Guardadas:</h2>
        <p>Si no recibes correos con la información de las alertas mira tu carpeta de spam o pon filtros más genéricos</p>
        <ul>
          {alertasGuardadas.map((alerta, index) => (
          <li key={alerta.id}>
            <h4>{alerta.data.tituloAlerta}</h4>
            <div className='info-alerta'>
              <button onClick={() => toggleProducts(alerta.id)}><FontAwesomeIcon icon={faShirt} /></button>
                {alerta.data.prenda && (
                  <p><strong>Prenda: </strong> {upperFL(alerta.data.prenda)}</p>
                )}
                {alerta.data.sexo && (
                  <p><strong>Sexo: </strong> {upperFL(alerta.data.sexo)}</p>
                )}
                {alerta.data.talla && (
                  <p><strong>Talla: </strong> {upperFL(alerta.data.talla)}</p>
                )}                            
                {alerta.data.estado && (
                  <p><strong>Estado: </strong> {upperFL(alerta.data.estado)}</p>
                )}
                {alerta.data.marca && (
                  <p><strong>Marca: </strong> {upperFL(alerta.data.marca)}</p>
                )}
                {alerta.data.color && (
                  <p><strong>Color: </strong> {upperFL(alerta.data.color)}</p>
                )}
                {alerta.data.ubicacion && (
                  <p><strong>Ubicacion: </strong> {upperFL(alerta.data.ubicacion)}</p>
                )}
                {alerta.data.precioMax && (
                  <p><strong>Precio Máximo: </strong> {upperFL(alerta.data.precioMax)}</p>
                )}
                <button className='boton-eliminar' onClick={() => handleDeleteAndRefresh(currentUser.uid,alerta.id)}>Eliminar</button>
            </div>
                {showProducts[alerta.id] && (
                  <div className='productos-alerta'>
                    {products[alerta.id] !== undefined ? (
                      products[alerta.id].length > 0 ? (
                        <ul className='products-container-alerts'>
                          {products[alerta.id].map((producto, index) => (
                            <li key={producto.id}>
                              <ProductosAlerts key={producto.id} producto={producto} state={state} user={currentUser} />
                              </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No hay productos que coincidan.</p>
                      )
                    ) : (
                      <p>Cargando productos...</p>
                    )}
                  </div>
                )}
                <div className='action-sub'>
                  <p>Para añadir más alertas y encontrar los productos que buscas suscríbete a nuestro <Link to="/premium">Plan Premium</Link></p>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
  }


  if(state === 7){
  return (
    <div className='alertas'>
      <div className='crear-alertas'>
        <h2>Crear Alerta de Producto</h2>
        <p>Los campos con * son obligatorios</p>
        <form onSubmit={handleSubmit} className='form-container'>
        <div>
            <label htmlFor="tituloAlerta">*Título:</label>
            <input
              type="text"
              id="tituloAlerta"
              name="tituloAlerta"
              value={alerta.tituloAlerta}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="prenda">*Prenda:</label>
            <select
              id="prenda"
              name="prenda"
              value={alerta.prenda}
              onChange={handleChange}
              required
            >
              <option value="">Seleccionar</option>
              <option value="abrigos">Abrigos</option>
              <option value="americanas">Americanas</option>
              <option value="batas">Batas</option>
              <option value="banaedores">Bañadores</option>
              <option value="bermudas">Bermudas</option>
              <option value="bikinies">Bikinies</option>
              <option value="blazers">Blazers</option>
              <option value="blusas">Blusas</option>
              <option value="botas">Botas</option>
              <option value="bufandas">Bufandas</option>
              <option value="calcetines">Calcetines</option>
              <option value="camisas">Camisas</option>
              <option value="camisetas">Camisetas</option>
              <option value="cardigans">Cardigans</option>
              <option value="chalecos">Chalecos</option>
              <option value="chandales">Chándales</option>
              <option value="chaquetas">Chaquetas</option>
              <option value="chupas">Chupas</option>
              <option value="cinturones">Cinturones</option>
              <option value="corbatas">Corbatas</option>
              <option value="faldas">Faldas</option>
              <option value="fulares">Fulares</option>
              <option value="gafas">Gafas</option>
              <option value="gorros">Gorros</option>
              <option value="guantes">Guantes</option>
              <option value="jerseys">Jerseys</option>
              <option value="leggings">Leggings</option>
              <option value="mallas">Mallas</option>
              <option value="minifaldas">Minifaldas</option>
              <option value="monos">Monos</option>
              <option value="pantalones">Pantalones</option>
              <option value="pijamas">Pijamas</option>
              <option value="polos">Polos</option>
              <option value="relojes">Relojes</option>
              <option value="sombreros">Sombreros</option>
              <option value="sudaderas">Sudaderas</option>
              <option value="tops">Tops</option>
              <option value="trajes">Trajes</option>
              <option value="vestidos">Vestidos</option>
              <option value="zapatillas">Zapatillas</option>
              <option value="zapatos">Zapatos</option>
            </select>
          </div>
          <div>
            <label htmlFor="sexo">*Sexo:</label>
            <select
              id="sexo"
              name="sexo"
              value={alerta.sexo}
              onChange={handleChange}
              required
            >
              <option value="">Seleccionar</option>
              <option value="hombre">Hombre</option>
              <option value="mujer">Mujer</option>
            </select>
          </div>
          <div>
            <label htmlFor="talla">*Talla:</label>
            <select
              id="talla"
              name="talla"
              value={alerta.talla}
              onChange={handleChange}
              required
            >
              <option value="">Seleccionar</option>
              <option value="">--Tallas Ropa</option>
              <option value="xs">XS</option>
              <option value="s">S</option>
              <option value="m">M</option>
              <option value="l">L</option>
              <option value="xl">XL</option>
              <option value="xxl">XXL</option>
              <option value="3xl">3XL</option>
              <option value="4xl">4XL</option>
              <option value="">--Tallas Calzado</option>
              <option value="35">35</option>
              <option value="36">36</option>
              <option value="37">37</option>
              <option value="38">38</option>
              <option value="39">39</option>
              <option value="40">40</option>
              <option value="41">41</option>
              <option value="42">42</option>
              <option value="43">43</option>
              <option value="44">44</option>
              <option value="45">45</option>
            </select>
          </div>
          <div>
            <label htmlFor="estado">*Estado:</label>
            <select
              id="estado"
              name="estado"
              value={alerta.estado}
              onChange={handleChange}
              required           
            >
              <option value="">Seleccionar</option>
              <option value="nuevo">Nuevo</option>
              <option value="como nuevo">Cómo nuevo</option>
              <option value="muy bueno">Muy bueno</option>
              <option value="bueno">Bueno</option>
              <option value="usado">Usado</option>
            </select>
          </div>
          <div>
            <label htmlFor="marca">Marca:</label>
            <select id="marca"
              name="marca"
              value={alerta.marca}
              onChange={handleChange}           
            >
              <option value="">Seleccionar</option>
              <option value="adidas">Adidas</option>
              <option value="abercrombie & fitch">Abercrombie & Fitch</option>
              <option value="air force">Air Force</option>
              <option value="alcott">Alcott</option>
              <option value="armand thiery">Armand Thiery</option>
              <option value="asics">Asics</option>
              <option value="asos">ASOS</option>
              <option value="balenciaga">Balenciaga</option>
              <option value="bershka">Bershka</option>
              <option value="bizzbee">Bizzbee</option>
              <option value="bonobo">Bonobo</option>
              <option value="boohoo">Boohoo</option>
              <option value="boohooman">BoohooMAN</option>
              <option value="brice">Brice</option>
              <option value="c&a">C&A</option>
              <option value="cuir">CUIR</option>
              <option value="calvin klein">Calvin Klein</option>
              <option value="cargo">Cargo</option>
              <option value="carhartt">Carhartt</option>
              <option value="carrera">Carrera</option>
              <option value="celio">Celio</option>
              <option value="champion">Champion</option>
              <option value="chevignon">Chevignon</option>
              <option value="columbia">Columbia</option>
              <option value="converse">Converse</option>
              <option value="cortefiel">Cortefiel</option>
              <option value="corteiz">Corteiz</option>
              <option value="decathlon">Decathlon</option>
              <option value="desigual">Desigual</option>
              <option value="devred">Devred</option>
              <option value="diadora">Diadora</option>
              <option value="dickies">Dickies</option>
              <option value="diesel">Diesel</option>
              <option value="dockers">Dockers</option>
              <option value="dsquared2">Dsquared2</option>
              <option value="easy wear">Easy Wear</option>
              <option value="eden park">Eden Park</option>
              <option value="el corte ingles">El Corte Inglés</option>
              <option value="element">Element</option>
              <option value="ellesse">Ellesse</option>
              <option value="esprit">Esprit</option>
              <option value="fila">FILA</option>
              <option value="fred perry">Fred Perry</option>
              <option value="g-star">G-Star</option>
              <option value="guess">GUESS</option>
              <option value="geox">Geox</option>
              <option value="giacca">Giacca</option>
              <option value="giuseppe zanotti">Giuseppe Zanotti</option>
              <option value="gemo">Gémo</option>
              <option value="h&m">H&M</option>
              <option value="hogan">Hogan</option>
              <option value="hollister">Hollister</option>
              <option value="hugo boss">Hugo Boss</option>
              <option value="inside(24)">Inside(24)</option>
              <option value="jack & jones">Jack & Jones</option>
              <option value="jaded london">Jaded London</option>
              <option value="jeckerson">Jeckerson</option>
              <option value="jennyfer">Jennyfer</option>
              <option value="joma">Joma</option>
              <option value="jordan">Jordan</option>
              <option value="jules">Jules</option>
              <option value="kalenji">Kalenji</option>
              <option value="kaporal">Kaporal</option>
              <option value="kappa">Kappa</option>
              <option value="kenzo">Kenzo</option>
              <option value="kiabi">Kiabi</option>
              <option value="kipsta">Kipsta</option>
              <option value="lacoste">Lacoste</option>
              <option value="le coq sportif">Le Coq Sportif</option>
              <option value="lee">Lee</option>
              <option value="lefties">Lefties</option>
              <option value="levi strauss & co.">Levi Strauss & Co.</option>
              <option value="levis">Levi's</option>
              <option value="lidl">Lidl</option>
              <option value="local">Local</option>
              <option value="lotto">Lotto</option>
              <option value="mizuno">MIZUNO</option>
              <option value="mango">Mango</option>
              <option value="massimo dutti">Massimo Dutti</option>
              <option value="minus twø">Minus Twø</option>
              <option value="munich">Munich</option>
              <option value="nasa">NASA</option>
              <option value="napapijri">Napapijri</option>
              <option value="new balance">New Balance</option>
              <option value="nike">Nike</option>
              <option value="ovs">OVS</option>
              <option value="off-white">Off-White</option>
              <option value="pepe jeans">Pepe Jeans</option>
              <option value="polo">Polo</option>
              <option value="primark">Primark</option>
              <option value="pull & bear">Pull & Bear</option>
              <option value="puma">Puma</option>
              <option value="pyrex">Pyrex</option>
              <option value="quiksilver">Quiksilver</option>
              <option value="ralph lauren">Ralph Lauren</option>
              <option value="redskins">Redskins</option>
              <option value="reebok">Reebok</option>
              <option value="salomon">Salomon</option>
              <option value="saucony">Saucony</option>
              <option value="scalpers">Scalpers</option>
              <option value="scarpe">Scarpe</option>
              <option value="schott">Schott</option>
              <option value="sfera">Sfera</option>
              <option value="shein">Shein</option>
              <option value="shimano">Shimano</option>
              <option value="springfield">Springfield</option>
              <option value="stradivarius">Stradivarius</option>
              <option value="superdry">Superdry</option>
              <option value="tex">TEX</option>
              <option value="teddy smith">Teddy Smith</option>
              <option value="the north face">The North Face</option>
              <option value="timberland">Timberland</option>
              <option value="tommy hilfiger">Tommy Hilfiger</option>
              <option value="tommy jeans">Tommy Jeans</option>
              <option value="umbro">Umbro</option>
              <option value="under armour">Under Armour</option>
              <option value="uniqlo">Uniqlo</option>
              <option value="uomo">Uomo</option>
              <option value="vans">Vans</option>
              <option value="vera pelle">Vera Pelle</option>
              <option value="vintage dressing">Vintage Dressing</option>
              <option value="volcom">Volcom</option>
              <option value="woolrich">Woolrich</option>
              <option value="yeezy">Yeezy</option>
              <option value="zara">Zara</option>


            </select>
          </div>
          <div>
            <label htmlFor="color">Color:</label>
            <select
              id="color"
              name="color"
              value={alerta.color}
              onChange={handleChange}           
            >
              <option value="">Seleccionar</option>
              <option value="amarillo">Amarillo</option>
              <option value="azul">Azul</option>
              <option value="beige">Beige</option>
              <option value="blanco">Blanco</option>
              <option value="celeste">Celeste</option>
              <option value="dorado">Dorado</option>
              <option value="gris">Gris</option>
              <option value="marron">Marrón</option>
              <option value="morado">Morado</option>
              <option value="naranja">Naranja</option>
              <option value="negro">Negro</option>
              <option value="plateado">Plateado</option>
              <option value="rojo">Rojo</option>
              <option value="rosa">Rosa</option>
              <option value="turquesa">Turquesa</option>
              <option value="verde">Verde</option>
              <option value="violeta">Violeta</option>
            </select>
          </div>
          <div>
            <label htmlFor="ubicacion">Ubicación:</label>
            <select
              id="ubicacion"
              name="ubicacion"
              value={alerta.ubicacion}
              onChange={handleChange}
            >
              <option value="">Seleccionar</option>
              <option value="alava">Álava</option>
              <option value="albacete">Albacete</option>
              <option value="alicante">Alicante</option>
              <option value="almeria">Almería</option>
              <option value="asturias">Asturias</option>
              <option value="avila">Ávila</option>
              <option value="badajoz">Badajoz</option>
              <option value="baleares">Baleares (Illes)</option>
              <option value="barcelona">Barcelona</option>
              <option value="burgos">Burgos</option>
              <option value="caceres">Cáceres</option>
              <option value="cadiz">Cádiz</option>
              <option value="cantabria">Cantabria</option>
              <option value="castellon">Castellón</option>
              <option value="ceuta">Ceuta</option>
              <option value="ciudadreal">Ciudad Real</option>
              <option value="cordoba">Córdoba</option>
              <option value="cuenca">Cuenca</option>
              <option value="girona">Gerona (Girona)</option>
              <option value="granada">Granada</option>
              <option value="guadalajara">Guadalajara</option>
              <option value="guipuzcoa">Guipúzcoa (Gipuzkoa)</option>
              <option value="huelva">Huelva</option>
              <option value="huesca">Huesca</option>
              <option value="jaen">Jaén</option>
              <option value="laCoruna">La Coruña (A Coruña)</option>
              <option value="larioja">La Rioja</option>
              <option value="lasPalmas">Las Palmas</option>
              <option value="leon">León</option>
              <option value="lleida">Lérida (Lleida)</option>
              <option value="lugo">Lugo</option>
              <option value="madrid">Madrid</option>
              <option value="malaga">Málaga</option>
              <option value="melilla">Melilla</option>
              <option value="murcia">Murcia</option>
              <option value="navarra">Navarra</option>
              <option value="ourense">Orense (Ourense)</option>
              <option value="palencia">Palencia</option>
              <option value="pontevedra">Pontevedra</option>
              <option value="salamanca">Salamanca</option>
              <option value="segovia">Segovia</option>
              <option value="sevilla">Sevilla</option>
              <option value="soria">Soria</option>
              <option value="tarragona">Tarragona</option>
              <option value="tenerife">Tenerife</option>
              <option value="teruel">Teruel</option>
              <option value="toledo">Toledo</option>
              <option value="valencia">Valencia</option>
              <option value="valladolid">Valladolid</option>
              <option value="vizcaya">Vizcaya (Bizkaia)</option>
              <option value="zamora">Zamora</option>
              <option value="zaragoza">Zaragoza</option>
            </select>
          </div>
          <div>
            <label htmlFor="precioMax">Precio Máximo:</label>
            <input
              type="number"
              id="precioMax"
              name="precioMax"
              value={alerta.precioMax}
              onChange={handleChange}          
            />
          </div>
          <button type="submit" className='botton-crear-alerta'>Crear Alerta</button>
        </form>
      </div>
      <div className='mostrar-alertas'>
        <h2>Alertas Guardadas:</h2>
        <p>Si no recibes correos con la información de las alertas mira tu carpeta de spam o pon filtros más genéricos</p>
        <ul>
          {alertasGuardadas.map((alerta, index) => (
          <li key={alerta.id}>
            <h4>{alerta.data.tituloAlerta}</h4>
            <div className='info-alerta'>
              <button onClick={() => toggleProducts(alerta.id)}><FontAwesomeIcon icon={faShirt} /></button>
                {alerta.data.prenda && (
                  <p><strong>Prenda: </strong> {upperFL(alerta.data.prenda)}</p>
                )}
                {alerta.data.sexo && (
                  <p><strong>Sexo: </strong> {upperFL(alerta.data.sexo)}</p>
                )}
                {alerta.data.talla && (
                  <p><strong>Talla: </strong> {upperFL(alerta.data.talla)}</p>
                )}                            
                {alerta.data.estado && (
                  <p><strong>Estado: </strong> {upperFL(alerta.data.estado)}</p>
                )}
                {alerta.data.marca && (
                  <p><strong>Marca: </strong> {upperFL(alerta.data.marca)}</p>
                )}
                {alerta.data.color && (
                  <p><strong>Color: </strong> {upperFL(alerta.data.color)}</p>
                )}
                {alerta.data.ubicacion && (
                  <p><strong>Ubicacion: </strong> {upperFL(alerta.data.ubicacion)}</p>
                )}
                {alerta.data.precioMax && (
                  <p><strong>Precio Máximo: </strong> {upperFL(alerta.data.precioMax)}</p>
                )}
                <button className='boton-eliminar' onClick={() => handleDeleteAndRefresh(currentUser.uid,alerta.id)}>Eliminar</button>
                </div>
                {showProducts[alerta.id] && (
                  <div className='productos-alerta'>
                    {products[alerta.id] !== undefined ? (
                      products[alerta.id].length > 0 ? (
                        <ul className='products-container-alerts'>
                          {products[alerta.id].map((producto, index) => (
                            <li key={producto.id}>
                              <ProductosAlerts key={producto.id} producto={producto} state={state} user={currentUser} />
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No hay productos que coincidan.</p>
                      )
                    ) : (
                      <p>Cargando productos...</p>
                    )}
                  </div>
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
  }

  return (
    <AuthProvider
      onUserLoggIn={handleUserLoggIn}
      onUserNotLoggIn={handleUserNotLoggIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserPremium={handleUserPremium}
      >
        <div>Loading...</div>
    </AuthProvider>
  );
};

