// Importa las funciones que necesitas del SDK de Firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';


// Configuración de tu aplicación Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Inicializa tu aplicación Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;


// Función para redirigir a Stripe Portal
export async function goStripePortal() {
    const functionRef = firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
  const { data } = await functionRef({
    returnUrl: window.location.origin,
    locale: "auto", // Optional, defaults to "auto"
    //configuration: process.env.REACT_APP_STRIPE_CONFIG, // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
  });
  window.location.assign(data.url);
}
