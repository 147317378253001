import React, { useState } from 'react';
import { borrarUsuario } from '../../firebase/config';
import { BorrarUserConfirmation } from '../BorrarUserConfirmation/BorrarUserConfirmation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserXmark} from '@fortawesome/free-solid-svg-icons'

export const BorrarUser = ({ usuario }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleEliminarClick = () => {
        setIsModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        await borrarUsuario();
        setIsModalOpen(false);
    };

    const handleCancelDelete = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <button onClick={handleEliminarClick}>
                <p><FontAwesomeIcon icon={faUserXmark} />Eliminar Usuario</p>
            </button>
            <BorrarUserConfirmation
                isOpen={isModalOpen}
                onCancel={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />
        </>
    );
};
