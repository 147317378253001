import Producto from '../Producto/Producto';
import './Productos.css';
import { AuthProvider } from '../AuthProvider/AuthProvider'
import { Compartir } from '../Compartir/Compartir';
import { searchClient, routing } from '../../firebase/algolia';
import { InstantSearch, SearchBox, Hits, RefinementList, Pagination, useInstantSearch, RangeInput, ClearRefinements, SortBy, Stats, InfiniteHits } from 'react-instantsearch';
import { upperFL } from '../../utils/utils'
import React, {useState, useEffect}from 'react'
import { getSubscriptionStatus} from '../../firebase/config'

export const Productos = () => {

  /*
    Stages:
    0: initiated
    1: loading
    2: login completed
    3: login but no username
    4: not logged
    5: ya existe el username
    6: nuevo username: click para continuar
    7: premium
    */
  
    const [state, setState] = useState(0);
    const [currentUser, setCurrentUser] = useState({})
    const [premium, setPremium] = useState([]);
    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => {
      const fetchSubs = async () => {
        try {
          if (currentUser && currentUser.uid) {
            const isPremium = await getSubscriptionStatus(currentUser.uid);
            setPremium(isPremium);
          }
        } catch (error) {
          console.error('Error al obtener la subscripción:', error);
        }
      };
    
      fetchSubs();
    }, [currentUser]);

    useEffect(() => {
      // Este efecto se ejecutará cada vez que currentUser cambie
      if (premium === true) {
        setState(7);
      }
    }, [currentUser, premium]);
  
  
    function handleUserPremium(user){
      setCurrentUser(user)
      setState(7)
      
    }
  
    function handleUserLoggIn(user){
        setCurrentUser(user)
        setState(2)
    }
  
    function handleUserNotRegistered(user){
        setState(4)
    }
  
    function handleUserNotLoggIn(){
        setState(4)
    }

  // filtro talla
  const sizeFilter = (items) => {
    const undesiredOptions = ['otros', 'unknown'];
    const filteredItems = items.filter(item => !undesiredOptions.includes(item.label));
    return filteredItems;
  };
  
  // filtro condition
  const conditionFilter = (items) => {
    const undesiredOptions = ['otros', 'unknown'];
    const filteredItems = items.filter(item => !undesiredOptions.includes(item.label));
    return filteredItems;
  };
  // filtro color
  const colorFilter = (items) => {
    const undesiredOptions = ['otros', 'unknown', '13c1ac'];
    const filteredItems = items.filter(item => !undesiredOptions.includes(item.label));
    return filteredItems;
  };
  // filtro ciudad
  const cityFilter = (items) => {
    const undesiredOptions = ['otros', 'unknown', '13c1ac'];
    const filteredItems = items.filter(item => !undesiredOptions.includes(item.label));
    return filteredItems;
  };

  function formatUrlParameters(url) {
    const params = new URLSearchParams(url.search);

    const query = params.getAll('query').join(', ')
    const clothe = params.getAll('clothe[0]').join(', ');
    const sex = params.getAll('sex[0]').join(', ');
    const web = params.getAll('web[0]').join(', ');
    const size = params.getAll('size[0]').join(', ');
    const condition = params.getAll('condition[0]').join(', ');
    const color = params.getAll('color[0]').join(', ');
    const city = params.getAll('city[0]').join(', ');
    const brand = params.getAll('brand[0]').join(', ');


    let title = '';
    if (query !== '') {
        title += `${query} `;
    } 
    if (clothe !== '') {
        title += `${clothe}`;
    } else {
        if(query === ""){
          title += 'Ropa de segunda mano vintage';
        } 
    }
    if (sex !== '') {
        title += ` de ${sex}`;
    }
    if (size !== '') {
        title += `, talla: ${size}`;
    }
    if (condition !== '') {
        title += `, ${condition}`;
    }
    if (color !== '') {
        title += `, color: ${color}`;
    }
    if (city !== '') {
        title += ` en ${city}`;
    }
    if (web !== '') {
      title += `, ${web}`;
    }
    if (brand !== '') {
      title += `, brand: ${brand}`;
    }

    return upperFL(title || 'Ropa de segunda mano vintage');
}

  const currentUrl = window.location.href;
  const exampleUrl = new URL(currentUrl);
  const formattedResult = formatUrlParameters(exampleUrl);
  const title = formattedResult;

  // No results
  function NoResultsBoundary({ children, fallback }) {
    const { results } = useInstantSearch();

    if (!results.__isArtificial && results.nbHits === 0) {
      return (
        <>
          {fallback}
          <div hidden>{children}</div>
        </>
      );
    }

    return children;
  }

  function NoResults() {
    const { indexUiState } = useInstantSearch();

    return (
      <div>
        <p>
          No results for <q>{indexUiState.query}</q>.
        </p>
      </div>
    );
  }
  useEffect(() => {
    if (showFilters) {
      document.body.style.overflow = 'hidden'; // Bloquea el desplazamiento del fondo
    } else {
      document.body.style.overflow = ''; // Restaura el desplazamiento
    }
    return () => {
      document.body.style.overflow = ''; // Limpia el efecto al desmontar
    };
  }, [showFilters]);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  if(state===4){
    return (

    <div>
      <InstantSearch indexName="productos_erastro" searchClient={searchClient} routing={routing}>
        <div className="search-container">
            <SearchBox placeholder={"Buscar producto"} className="buscador-principal" />
        </div>

        <div className="container">
            {/* Botón de mostrar filtros */}
            <button className="toggle-filters-button" onClick={toggleFilters}>
              {showFilters ? 'Ocultar filtros' : 'Mostrar filtros'}
            </button>

            {/* Superposición para cerrar filtros */}
            {showFilters && <div className="overlay" onClick={toggleFilters}></div>}

            {/* Contenedor de filtros */}
            <div className={`filter-container ${showFilters ? 'visible' : ''}`}>
              {/* Botón de cerrar filtros (solo visible en móviles) */}
              <button className="close-filters-button" onClick={toggleFilters}>
                ✕ Cerrar
              </button>
              <div className="filter">
                <ClearRefinements translations={{ resetButtonText: 'Borrar filtros' }} />
                <h3>Prenda</h3>
                <RefinementList attribute="clothe" showMore={true} showMoreLimit={40} searchable={true} />
                <h3>Sexo</h3>
                <RefinementList attribute="sex" />
                <h3>Precio</h3>
                <RangeInput attribute="price" />
                <h3>Talla</h3>
                <RefinementList attribute="size" limit={7} showMore={true} searchable={true} />
                <h3>Estado</h3>
                <RefinementList attribute="condition" limit={5} />
                <h3>Color</h3>
                <RefinementList attribute="color" limit={5} showMore={true} searchable={true} />
                <h3>Marca</h3>
                <RefinementList attribute="brand" limit={5} showMore={true} searchable={true} />
                <h3>Ubicación</h3>
                <RefinementList attribute="city" limit={5} showMore={true} searchable={true} />
              </div>
            </div>

            <div className='productos'>
              <NoResultsBoundary fallback={<NoResults />}>
              <h1>{title}</h1>
              <p> Descubre las mejores oportunidades en tiendas online y ahorra como nunca antes.</p>
              <Compartir></Compartir>
              <Stats/>
              <Hits hitComponent={({ hit }) => <Producto hit={hit} state={state} />} />
              </NoResultsBoundary>
            </div>
          

        </div>
        <div className="pagination-container">
          <Pagination className="pagination" totalPages={10} />
        </div>
      </InstantSearch>
      <p className='container-p'>Aquí tienes {title}. Explora nuestro catálogo exclusivo de prendas vintage de segunda mano, cuidadosamente seleccionadas para ti. Encuentra inspiración en una amplia variedad de estilos y épocas. Navega por nuestra colección y encuentra piezas únicas que te transportarán a otras décadas. Descubre las mejores ofertas a través de nuestras alertas y sumérgete en la moda retro con nosotros.</p>
    </div>
    );
  }

  if(state===2){
    return (

    <div>
      <InstantSearch indexName="productos_erastro" searchClient={searchClient} routing={routing}>
        <div className="search-container">
            <SearchBox placeholder={"Buscar producto"} className="buscador-principal" />
        </div>

        <div className="container">
            {/* Botón de mostrar filtros */}
            <button className="toggle-filters-button" onClick={toggleFilters}>
              {showFilters ? 'Ocultar filtros' : 'Mostrar filtros'}
            </button>

            {/* Superposición para cerrar filtros */}
            {showFilters && <div className="overlay" onClick={toggleFilters}></div>}

            {/* Contenedor de filtros */}
            <div className={`filter-container ${showFilters ? 'visible' : ''}`}>
              {/* Botón de cerrar filtros (solo visible en móviles) */}
              <button className="close-filters-button" onClick={toggleFilters}>
                ✕ Cerrar
              </button>
              <div className="filter">
                <ClearRefinements translations={{ resetButtonText: 'Borrar filtros' }} />
                <h3>Prenda</h3>
                <RefinementList attribute="clothe" showMore={true} showMoreLimit={40} searchable={true} />
                <h3>Sexo</h3>
                <RefinementList attribute="sex" />
                <h3>Precio</h3>
                <RangeInput attribute="price" />
                <h3>Talla</h3>
                <RefinementList attribute="size" limit={7} showMore={true} searchable={true} />
                <h3>Estado</h3>
                <RefinementList attribute="condition" limit={5} />
                <h3>Color</h3>
                <RefinementList attribute="color" limit={5} showMore={true} searchable={true} />
                <h3>Marca</h3>
                <RefinementList attribute="brand" limit={5} showMore={true} searchable={true} />
                <h3>Ubicación</h3>
                <RefinementList attribute="city" limit={5} showMore={true} searchable={true} />
              </div>
            </div>

            <div className='productos'>
              <NoResultsBoundary fallback={<NoResults />}>
              <h1>{title}</h1>
              <p> Descubre las mejores oportunidades en tiendas online y ahorra como nunca antes.</p>
              <Compartir></Compartir>
              <Stats/>
              <SortBy
                items={[
                  { label: 'Relevantes', value: 'productos_erastro' },
                  { label: 'Precio (asc)', value: 'productos_price_asc' },
                  { label: 'Precio (desc)', value: 'productos_price_des' }
                ]}
              />
              <Hits hitComponent={({ hit }) => <Producto hit={hit} state={state} />} />
              </NoResultsBoundary>
            </div>
          

        </div>
        <div className="pagination-container">
          <Pagination className="pagination" totalPages={10} />
        </div>
      </InstantSearch>
      <p className='container-p'>Aquí tienes {title}. Explora nuestro catálogo exclusivo de prendas vintage de segunda mano, cuidadosamente seleccionadas para ti. Encuentra inspiración en una amplia variedad de estilos y épocas. Navega por nuestra colección y encuentra piezas únicas que te transportarán a otras décadas. Descubre las mejores ofertas a través de nuestras alertas y sumérgete en la moda retro con nosotros.</p>
    </div>
    );
  }
  if(state===7){
    return ( 
      <div>
        <InstantSearch indexName="productos_erastro" searchClient={searchClient} routing={routing}>
          <div className="search-container">
            <SearchBox placeholder={"Buscar producto"} className="buscador-principal" />
          </div>

          <div className="container">
            {/* Botón de mostrar filtros */}
            <button className="toggle-filters-button" onClick={toggleFilters}>
              {showFilters ? 'Ocultar filtros' : 'Mostrar filtros'}
            </button>

            {/* Superposición para cerrar filtros */}
            {showFilters && <div className="overlay" onClick={toggleFilters}></div>}

            {/* Contenedor de filtros */}
            <div className={`filter-container ${showFilters ? 'visible' : ''}`}>
              {/* Botón de cerrar filtros (solo visible en móviles) */}
              <button className="close-filters-button" onClick={toggleFilters}>
                ✕ Cerrar
              </button>
              <div className="filter">
                <ClearRefinements translations={{ resetButtonText: 'Borrar filtros' }} />
                <h3>Prenda</h3>
                <RefinementList attribute="clothe" showMore={true} showMoreLimit={40} searchable={true} />
                <h3>Sexo</h3>
                <RefinementList attribute="sex" />
                <h3>Precio</h3>
                <RangeInput attribute="price" />
                <h3>Talla</h3>
                <RefinementList attribute="size" limit={7} showMore={true} searchable={true} />
                <h3>Estado</h3>
                <RefinementList attribute="condition" limit={5} />
                <h3>Color</h3>
                <RefinementList attribute="color" limit={5} showMore={true} searchable={true} />
                <h3>Marca</h3>
                <RefinementList attribute="brand" limit={5} showMore={true} searchable={true} />
                <h3>Ubicación</h3>
                <RefinementList attribute="city" limit={5} showMore={true} searchable={true} />
              </div>
            </div>

            <div className="productos">
              <NoResultsBoundary fallback={<NoResults />}>
                <h1>{title}</h1>
                <p> Descubre las mejores oportunidades en tiendas online y ahorra como nunca antes.</p>
                <Compartir />
                <div className="stats-sort">
                  <Stats />
                  <SortBy
                    items={[
                      { label: 'Relevantes', value: 'productos_erastro' },
                      { label: 'Precio (asc)', value: 'productos_price_asc' },
                      { label: 'Precio (desc)', value: 'productos_price_des' },
                    ]}
                  />
                </div>
                <Hits hitComponent={({ hit }) => <Producto hit={hit} state={state} user={currentUser} />} />
              </NoResultsBoundary>
            </div>
          </div>

          <div className="pagination-container">
            <Pagination className="pagination" totalPages={10} />
          </div>
        </InstantSearch>
        <p className="container-p">Aquí tienes {title}. Explora nuestro catálogo exclusivo...</p>
      </div>
    );
  }
  return (
    <AuthProvider 
    onUserLoggIn={handleUserLoggIn}
    onUserNotLoggIn={handleUserNotLoggIn}
    onUserNotRegistered={handleUserNotRegistered}
    onUserPremium={handleUserPremium}
    >
    </AuthProvider>
  )
}

