import React from 'react'
import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TwitterShareButton,
    TwitterIcon,
    TelegramShareButton,
    TelegramIcon,
    EmailShareButton,
    EmailIcon,
    RedditShareButton,
    RedditIcon
  } from 'react-share';
import './Compartir.css';



export const Compartir = () => {
    const url = 'http://localhost:3000/'
    const titulo = 'eRastro'
  return (
    <div className='compartir'>
      <FacebookShareButton url={url}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <WhatsappShareButton url={url} title={titulo}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>

      <TwitterShareButton url={url} title={titulo}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <TelegramShareButton url={url} title={titulo}>
        <TelegramIcon size={32} round />
      </TelegramShareButton>

      <EmailShareButton url={url} subject={titulo}>
        <EmailIcon size={32} round />
      </EmailShareButton>

      <RedditShareButton url={url} title={titulo}>
        <RedditIcon size={32} round />
      </RedditShareButton>

    </div>
  )
}
