import React, {useState, useEffect}from 'react'
import { removeFromFavorites, addToFavorites, guardarLogProducto} from '../../firebase/config'
import { Link } from 'react-router-dom'
import { upperFL } from '../../utils/utils'
import "./Producto.css"


export const Producto = ( {hit, state, user} ) => {

  /*
    Stages:
    0: initiated
    1: loading
    2: login completed
    3: login but no username
    4: not logged
    5: ya existe el username
    6: nuevo username: click para continuar
    7: premium
    */
  
  const currentUser = user;
  const [userLikes, setUserLikes] = useState([]);

  // Efecto para actualizar los likes locales cuando cambia el usuario
  useEffect(() => {
    if (currentUser && currentUser.likes) {
      setUserLikes(currentUser.likes);
    }
  }, [currentUser]);

  const handleLikeClick = async (hit) => {
    if (currentUser) {
      try {
        if (userLikes.includes(hit.objectID)) {
          // Si ya está en favoritos, elimínalo
          await removeFromFavorites(currentUser.uid, hit.objectID);
        } else {
          // Si no está en favoritos, añádelo
          await addToFavorites(currentUser.uid, hit.objectID);
        }

        // Actualiza el estado local con los likes actualizados
        setUserLikes((prevLikes) =>
          prevLikes.includes(hit.objectID)
            ? prevLikes.filter((like) => like !== hit.objectID)
            : [...prevLikes, hit.objectID]
        );
      } catch (error) {
        console.error('Error al manejar el like:', error);
      }
    }
  }

  const handleProductClick = async (hit, currentUser, state) => {
    if (!currentUser) {
      console.warn('Usuario no logueado. No se registrará el evento.');
      return;
    }
  
    const logData = {
      user: currentUser.uid, // ID del usuario
      username:currentUser.username,
      tipo_prenda: hit.clothe, // Nombre del producto
      size: hit.size,
      brand: hit.brand,
      estate: hit.condition,
      city_producto: hit.city,
      platform: hit.web,
      price: hit.price,
      category: hit.category, // Si tienes categorías
      color: hit.color,
      sex_product: hit.sex, // Si el producto tiene género
      sex_user: currentUser.gender, // Suponiendo que el perfil tiene género
      birthdate_user: currentUser.birthdate, // Suponiendo que el perfil tiene edad
      province_user: currentUser.province, // Localización del usuario
      is_premium: state === 7, // Si el usuario es premium
    };
  
    try {
      await guardarLogProducto(logData); // Envía los datos al backend
    } catch (error) {
      console.error(error);
    }
  };

  if(state===2){
    return (
      <div className="producto-card">
          <img src={hit.images} alt={upperFL(hit.title)}/>
          <div className="producto-info">
              <h3 className='titulo-producto' >{upperFL(hit.title)}</h3>
              <ul className="caracteristicas">
                  <li><strong>Talla:</strong> {hit.size.toUpperCase()}</li>
                  <li><strong>Estado:</strong> {upperFL(hit.condition)}</li>
                  <li><strong>Marca:</strong> {upperFL(hit.brand)}</li>
                  <li><strong>Ciudad:</strong> {upperFL(hit.city)}</li>
                  <li><strong>Web:</strong> {upperFL(hit.web)}</li>
                  <li><strong>Precio:</strong> {hit.price}€</li>
              </ul>
              <div className='botones-producto'>
                <a
                    href={hit.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => handleProductClick(hit, currentUser, state)}
                  >
                    <button>Ver Producto</button>
                </a>
                <Link to="/premium">
                  <button className='boton-favoritos'>
                    {'Hazte Premium para agregar favoritos'}
                  </button>
                </Link>
              </div>
              
          </div>
      </div>
    )
  }

  if(state === 4){
    return (
      <div className="producto-card">
          <img src={hit.images} alt={upperFL(hit.title)}/>
          <div className="producto-info">
              <h3 className='titulo-producto' >{upperFL(hit.title)}</h3>
              <ul className="caracteristicas">
                  <li><strong>Talla:</strong> {hit.size.toUpperCase()}</li>
                  <li><strong>Estado:</strong> {upperFL(hit.condition)}</li>
                  <li><strong>Marca:</strong> {upperFL(hit.brand)}</li>
                  <li><strong>Ciudad:</strong> {upperFL(hit.city)}</li>
                  <li><strong>Web:</strong> <Link to="/login">Login para ver</Link></li>
                  <li><strong>Precio:</strong> {hit.price}€</li>
              </ul>
              <div className='botones-producto'>
                <Link to="/login"><button>Login para ver el producto</button></Link>
              </div>
          </div>
      </div>
    )
  }
  if(state===7){
    return (
      <div className="producto-card">
          <img src={hit.images} alt={upperFL(hit.title)}/>
          <div className="producto-info">
              <h3 className='titulo-producto'>{upperFL(hit.title)}</h3>
              <ul className="caracteristicas">
              <li><strong>Talla:</strong> {hit.size.toUpperCase()}</li>
                  <li><strong>Estado:</strong> {upperFL(hit.condition)}</li>
                  <li><strong>Marca:</strong> {upperFL(hit.brand)}</li>
                  <li><strong>Ciudad:</strong> {upperFL(hit.city)}</li>
                  <li><strong>Web:</strong> {upperFL(hit.web)}</li>
                  <li><strong>Precio:</strong> {hit.price}€</li>
              </ul>
              <div className='botones-producto'>
                <a
                  href={hit.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleProductClick(hit, currentUser, state)}
                >
                  <button>Ver Producto</button>
                </a>
                <button 
                 className={`boton-favoritos ${userLikes.includes(hit.objectID) ? 'quitar' : 'agregar'}`}
                 onClick={() => handleLikeClick(hit)}>
                  {userLikes.includes(hit.objectID) ? 'Quitar de favoritos' : 'Agregar a favoritos'}
                </button>
              </div>
          </div>
      </div>
    )
  }
}

export default Producto
