import './App.css';
import ReactGA from "react-ga4";
import { BrowserRouter, Routes, Route , useLocation} from 'react-router-dom';
import { Navbar } from './components/Navbar/Navbar';
import { Footer } from './components/Footer/Footer';
import { Productos } from './components/Productos/Productos';
import { Usuario } from './components/Usuario/Usuario';
import { Favoritos } from './components/Favoritos/Favoritos';
import { Contacto } from './components/Contacto/Contacto';
import { Nosotros } from './components/Nosotros/Nosotros';
import { Login } from './components/Login/Login';
import { Signout } from './components/Signout/Signout';
import { EligeUsuario } from './components/EligeUsuario/EligeUsuario';
import { Premium } from './components/Premium/Premium';
import { Alertas } from './components/Alertas/Alertas';
import { AvisoLegal } from './components/PaginasLegales/AvisoLegal/AvisoLegal';
import { PoliticaCookies } from './components/PaginasLegales/PoliticaCookies/PoliticaCookies';
import { PoliticaPrivacidad } from './components/PaginasLegales/PoliticaPrivacidad/PoliticaPrivacidad';

function App() {

  ReactGA.initialize("G-ZENR43C1PL");
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });


  return (
    <div className="App">
      <BrowserRouter>
        <header>
          <Navbar/>
        </header>

        <Routes>
          <Route path='/' element={<Productos/>} />
          <Route path='usuario' element={<Usuario/>} />
          <Route path='favoritos' element={<Favoritos/>} />
          <Route path='contacto' element={<Contacto/>} />
          <Route path='nosotros' element={<Nosotros/>} />
          <Route path='login' element={<Login/>} />
          <Route path='signout' element={<Signout/>} />
          <Route path='elige-usuario' element={<EligeUsuario/>} />
          <Route path='premium' element={<Premium/>} />
          <Route path='alertas' element={<Alertas/>} />
          <Route path='aviso-legal' element={<AvisoLegal/>} />
          <Route path='politica-cookies' element={<PoliticaCookies/>} />
          <Route path='politica-privacidad' element={<PoliticaPrivacidad/>} />
        </Routes>

        <Footer />
      </BrowserRouter>
 
    </div>
  );
}

export default App;
