import React from 'react';
//import { useForm } from 'react-hook-form';
import './Contacto.css'; // Asegúrate de tener estilos CSS para tu formulario

export const Contacto = () => {
  /*const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    // Aquí puedes manejar la lógica de envío del formulario
    console.log('Datos del formulario:', data);
  };*/

  return(
    <div>
      <h2>Contacto</h2>
      <p>Para contactar con nosotros puedes enviarnos un correo a la siguiente dirección <strong>erastroapp@gmail.com</strong></p>
    </div>
  )

  /*return (
    <div className="contact-form">
      <h2>Formulario de Contacto</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="nombre">Nombre:</label>
        <input type="text" id="nombre" {...register('nombre', { required: true })} />
        {errors.nombre && <span className="error">Este campo es obligatorio</span>}

        <label htmlFor="asunto">Asunto:</label>
        <input type="text" id="asunto" {...register('asunto', { required: true })} />
        {errors.asunto && <span className="error">Este campo es obligatorio</span>}

        <label htmlFor="email">Correo Electrónico:</label>
        <input type="email" id="email" {...register('email', { required: true })} />
        {errors.email && <span className="error">Este campo es obligatorio</span>}

        <label htmlFor="mensaje">Mensaje:</label>
        <textarea id="mensaje" {...register('mensaje', { required: true })} />
        {errors.mensaje && <span className="error">Este campo es obligatorio</span>}

        <button type="submit">Enviar Mensaje</button>
      </form>
    </div>
  );*/
};
