import React from 'react'
import { auth } from '../../firebase/config';
import "./Signout.css"

export const Signout = () => {
  const handleLogout = async () => {
    try {
      await auth.signOut();
      console.log('Usuario desconectado exitosamente.');
    } catch (error) {
      console.error('Error al cerrar sesión:', error.message);
    }
  };

  return (
    <button className='signout-button' onClick={handleLogout}><p>Cerrar Sesión</p></button>
  );
}
