import React from 'react';
import './BorrarUserConfirmation.css';

export const BorrarUserConfirmation = ({ isOpen, onCancel, onConfirm }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <h3>¿Estás seguro de que deseas eliminar este usuario?</h3>
                <div className="modal-buttons">
                    <button onClick={onConfirm}>Reintroducir los credenciales para eliminar el usuario</button>
                    <button onClick={onCancel}>Cancelar</button>
                </div>
            </div>
        </div>
    );
};

