export function upperFL(inputString) {
  const words = inputString.split(/\s+/);

  // Capitalizar la primera letra de cada palabra
  const capitalizedWords = words.map(word =>
    word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Unir las palabras de nuevo en una cadena
  const resultString = capitalizedWords.join(' ');

  return resultString;
}

export function fecha() {
  const fechaActual = new Date();

  // Obtener componentes de la fecha
  const dia = String(fechaActual.getDate()).padStart(2, '0');
  const mes = String(fechaActual.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
  const año = fechaActual.getFullYear();
  const horas = String(fechaActual.getHours()).padStart(2, '0');
  const minutos = String(fechaActual.getMinutes()).padStart(2, '0');

  // Crear la cadena de fecha formateada
  const fechaFormateada = `${dia}/${mes}/${año} ${horas}:${minutos}`;

  return fechaFormateada;
}
