import React from 'react'
import "./Navbar.css"
import { Link } from 'react-router-dom'
export const Navbar = () => {
  return (
    <nav className='navegador-principal'>
        <Link to="/"><h2>eRastro</h2></Link>
        <ul>
            <li><Link to="/usuario">Mi perfil</Link></li>
            <li><Link to="/nosotros">Nosotros</Link></li>
        </ul>
    </nav>
  )
}
